module.exports = {
  en: {
    is_default: true,
    displayName: 'English',
    shortCut: 'EN',
    path: 'en',
    flag: 'GBR',
    /* hreflang: `${language_code}[-${regional_code}]`
     *
     * language_code: ISO 639-1 format, https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
     * regional_code: ISO 3166-1 alpha 2 format, https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
     * */
    hreflang: 'en-gb',
    iso639: 'en',
    iso3166: 'gb',
  },
  sv: {
    displayName: 'Swedish',
    shortCut: 'SV',
    path: 'sv',
    flag: 'SWE',
    hreflang: 'sv-se',
    iso639: 'sv',
    iso3166: 'se',
  },
  ru: {
    displayName: 'Russian',
    shortCut: 'RU',
    path: 'ru',
    disabled: false,
    flag: 'RUS',
    hreflang: 'ru',
    iso639: 'ru',
    iso3166: '',
  },
  ua: {
    displayName: 'Ukrainian',
    shortCut: 'UA',
    path: 'ua',
    disabled: true,
    flag: 'UKR',
    hreflang: 'uk-ua',
    iso639: 'uk',
    iso3166: 'ua',
  },
};
