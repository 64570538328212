'use strict';

const home_route = {
  id: 'home',
  pathList: {
    sv: {
      path: '/sv/hem',
      pageName: 'Hem',
    },
    en: {
      path: '/en/home',
      pageName: 'Home',
    },
    ru: {
      path: '/ru/главная',
      pageName: 'Главная',
    },
    ua: {
      path: '/ua/головна',
      pageName: 'Головна',
    },
  },
  routeParams: null,
  context: {},
};

/**
 * page_path-to-language_redirects
 * <pre>
 * uri_path = '/' + page_path;
 * </pre>
 * */
const routes = new Map([
  ['', home_route],
  [
    'privacy',
    {
      id: 'privacy',
      pathList: {
        sv: {
          path: '/sv/privacy',
          pageName: 'privacy sv',
        },
        en: {
          path: '/en/privacy',
          pageName: 'privacy en',
        },
        ru: {
          path: '/ru/конфиденциальность',
          pageName: 'privacy ru',
        },
        ua: {
          path: '/ua/конфіденційність',
          pageName: 'privacy ua',
        },
      },
      routeParams: null,
      context: {},
    },
  ],
  [
    'terms',
    {
      id: 'terms',
      pathList: {
        sv: {
          path: '/sv/terms',
          pageName: 'Terms',
        },
        en: {
          path: '/en/terms',
          pageName: 'Terms & Conditions',
        },
        ru: {
          path: '/ru/terms',
          pageName: 'Terms',
        },
        ua: {
          path: '/ua/terms',
          pageName: 'Terms',
        },
      },
      routeParams: null,
      context: {},
    },
  ],
  [
    'firstcake',
    {
      id: 'firstcake',
      pathList: {
        sv: {
          path: '/sv/firstcake',
          pageName: 'First Cake',
        },
        en: {
          path: '/en/firstcake',
          pageName: 'First Cake',
        },
        ru: {
          path: '/ru/firstcake',
          pageName: 'First Cake',
        },
        ua: {
          path: '/ua/firstcake',
          pageName: 'First Cake',
        },
      },
      routeParams: null,
      context: {},
    },
  ],
]);

module.exports = routes;
