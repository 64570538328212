export const anchors = {
  HomeTop: 'home-top',
  ShortIntro: 'short-introduction',
  WeCreate: 'we-create',
  OurAdvantage: 'our-advantages',
  Portfolio: 'portfolio',
  IgFeed: 'instagram-feed',
  Testimonials: 'testimonials',
  Ordering: 'ordering',
  AboutUs: 'about-us',
  ContactUs: 'contact-us',
  GetDiscount: 'get-discount',
  Footer: 'footer',
};

export const routesList = [
  {
    name: 'Our Advantages',
    to: `/#${anchors.OurAdvantage}`,
    priority: 1,
  },
  {
    name: 'Portfolio',
    to: `/#${anchors.Portfolio}`,
    priority: 1,
  },
  {
    name: 'About Us',
    to: `/#${anchors.AboutUs}`,
    priority: 1,
  },
  {
    name: 'Ordering',
    to: `/#${anchors.Ordering}`,
    priority: 1,
  },
  {
    name: 'Contact Us',
    to: `/#${anchors.ContactUs}`,
    priority: 1,
  },
];
