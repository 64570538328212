import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import MUIDrawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import { routesList } from '@config/headerRoutes';
import TopMenuItem from '@unicat/TopMenu/TopMenuItem';
import { useStyles } from './style';
import { config } from '@config';
import { ReactComponent as Logo } from '@assets/logo_unicat_se_purple.svg';
import { ReactComponent as Close } from '@assets/icons/close_27_px.svg';
import SmallFooter from '@unicat/TopMenu/Drawer/SmallFooter';
import t from '@unicat/i18n/Translate';
import LanguageSwitcher from '@unicat/i18n/LanguageSwitcher';

const Drawer = ({ mobileOpen, handleDrawerToggle }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Hidden only={['xl', 'lg']} implementation="css">
      <nav className={classes.drawer} aria-label="navigation">
        <MUIDrawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'left' : 'right'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          <div className={classes.drawerWrapper}>
            {/** Toolbar */}
            <Toolbar className={clsx(classes.toolbar, classes.drawerToolbar)}>
              <Logo className={classes.drawerToolbarLogo} />
              <LanguageSwitcher />
              <div className={classes.wrapperLangSwAndClose}>
                <Close className={classes.drawerToolbarClose} onClick={handleDrawerToggle} />
              </div>
            </Toolbar>
            <Divider />
            {/** Menu items */}
            <List className={classes.drawerNavList}>
              {routesList.map((x) => (
                <TopMenuItem key={`${x.to}${x.name}`} name={t(x.name)} to={x.to} />
              ))}
            </List>
            {/** Footer */}
            <SmallFooter config={config} />
          </div>
        </MUIDrawer>
      </nav>
    </Hidden>
  );
};

Drawer.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default Drawer;
