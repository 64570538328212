import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';
import { config } from '@config';
import SocialLink from './SocialLink';

import { ReactComponent as Facebook } from '@assets/icons/socials/facebook_w_bg.svg';
import { ReactComponent as Instagram } from '@assets/icons/socials/insta_w_bg.svg';
import { ReactComponent as Messenger } from '@assets/icons/socials/messenger_w_bg.svg';
import { ReactComponent as Twitter } from '@assets/icons/socials/twitter_w_bg.svg';

const SocialButtons = ({ config }) => {
  const classes = useStyles();
  const { fb, instagram, messenger, twitter } = config.socialLinks;

  return (
    <div className={classes.socialLinkWrapper}>
      <SocialLink className={clsx([classes.link, classes.insta])} href={instagram}>
        <Instagram />
      </SocialLink>
      <SocialLink className={clsx([classes.link, classes.fbm])} href={messenger}>
        <Messenger />
      </SocialLink>
      <SocialLink className={clsx([classes.link, classes.fb])} href={fb}>
        <Facebook />
      </SocialLink>
      <SocialLink className={clsx([classes.link, classes.twitter])} href={twitter}>
        <Twitter />
      </SocialLink>
    </div>
  );
};

SocialButtons.defaultProps = {
  config,
};
SocialButtons.propTypes = {
  config: PropTypes.object.isRequired,
};

export default SocialButtons;
