import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const palette = {
  background: {
    default: '#fef0f1',
  },
  primary: {
    main: '#8781bd',
    dark: '#5a5388',
  },
  secondary: {
    main: '#fdb913',
    light: '#0044ff',
  },

  purpleTextColor: '#8781bd',
  darkPurpleTextColor: '#5A5388',
  whiteTextColor: '#ffffff',
  grayTextColor: '#8b8484',
  pinkTextColor: '#f0bfc3',
  pinkBackgroundColor: '#fef0f1',
  yellowActionColor: '#f9bb16',

  darkPurple: '#5a5388',
  purple: '#8781bd',
  yellow: '#fdb913',
  pink: '#fde8e6',
  darkOrange: '#b83b30',
  orange: '#f05a28',
  gray: '#8b8484',
  black: '#000000',
  white: '#ffffff',
  purpleDarker: '#938dc7',

  shadowColor: '#B8B7D3',
  contentOffsetTopBottom: 150,
  contentMinimalHeight: 800,
};

const typography = {
  textsFontFamily: ["'Open Sans'", 'sans-serif'].join(','),
  lemonBird: ["'Lemon Bird'"].join(','),
  patricHand: ["'Patrick Hand'"].join(','),
  title2Size: '40px',
  title2fontSize: '35px',
  title3fontSize: '23px',
  textSize: '15px',
};

const fontBasis = {
  fontWeight: 400,
  fontFamily: typography.textsFontFamily,
  fontSize: typography.textSize,
  lineHeight: '1.55',
};

const dimensions = {
  toolbarHeight: 70,
  contentOffsetTopBottom: 150,
  contentMinimalHeight: 800,
  // 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  smOffsetsLeftRight: '50px',
};

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette,
  typography: {
    ...typography,
    /* helpers */
    normalGrayText: {
      ...fontBasis,
      color: palette.grayTextColor,
      textShadow: `0 0 1px ${palette.grayTextColor}`,
    },
    normalPurpleText: {
      ...fontBasis,
      color: palette.purpleTextColor,
      textShadow: `0 0 1px ${palette.purpleTextColor}`,
    },
    normalWhiteText: {
      ...fontBasis,
      color: palette.whiteTextColor,
      textShadow: `0 0 1px ${palette.whiteTextColor}`,
    },
    h2PurpleText: {
      fontFamily: typography.textsFontFamily,
      fontWeight: 'bold',
      fontSize: typography.title2Size,
      letterSpacing: 0.05,
      lineHeight: 1.05,
      color: palette.purpleTextColor,
      textShadow: `0 0 2px ${palette.white}`,
    },
    titlePurpleText: {
      fontFamily: typography.textsFontFamily,
      fontWeight: 600,
      textShadow: `0 0 1px ${palette.darkPurpleTextColor}`,
      fontSize: typography.title3fontSize,
      color: palette.purpleTextColor,
    },
    actionPurpleText: {
      fontFamily: typography.textsFontFamily,
      fontWeight: 600,
      color: palette.darkPurpleTextColor,
      fontSize: typography.textSize,
      lineHeight: '1.55',
    },
    /* Change theme for all pages */
    fontSize: 15,
    fontFamily: typography.textsFontFamily,
  },
  dimensions,
  contentContainer: {},
  actionLinkButton: {
    marginTop: 20,
    backgroundColor: palette.yellowActionColor,
    display: 'inline-block',
    padding: '0.75rem 2.5rem',
    borderRadius: '3rem',
    transition: 'transform 0.25s',
    [breakpoints.up('md')]: {
      '&:hover': {
        transform: 'scale(0.95)',
      },
    },
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
      alignSelf: 'center',
    },
    '& p': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
    },
  },
  buttonOutline: {
    cursor: 'pointer',
    border: 'unset',
    '&:focus:not(:focus-visible)': {
      outline: 'none',
    },
  },
});

export default theme;

/// TODO: global wrapper for each block
