import { makeStyles } from '@material-ui/core/styles';
import bgSvg from '@backgrounds/TopMenu/bg_footer.svg';

const drawerWidth = 360;

// 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    fontFamily: theme.typography.lemonBird,

    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    background: 'unset',
    boxShadow: 'unset',
    [theme.breakpoints.up('sm')]: {},
    transition: 'background-color 0.35s',
  },
  stuck: {
    backgroundColor: 'white',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    outline: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.9,
    },
  },
  toolbar: {
    minHeight: theme.dimensions.toolbarHeight,
    maxWidth: 1280,
    width: '100%',
    margin: '0 auto',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  link: {
    ...theme.typography.normalPurpleText,
    borderColor: 'transparent',
    borderStyle: 'solid',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginLeft: theme.spacing(0.9),
      marginRight: theme.spacing(0.9),
    },
    [theme.breakpoints.down(700)]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        opacity: 0.9,
      },
    },
  },
  socialIconsWrapper: {
    flex: 1,
    width: 180,
    maxWidth: 180,
    minWidth: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      minWidth: 60,
    },
  },
  socialIcon: {
    display: 'flex',
    '&:hover': {
      opacity: 0.9,
    },
  },
  routesList: {},
  leftBar: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  rightBar: {
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      height: 60,
    },
  },
  logo: {
    flex: 1,
    height: 34,
    width: 104,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        opacity: 0.9,
      },
    },
  },
  listItemTextRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  listItemTextPrimary: {
    // fontFamily: fontFamily,
    // color: purpleTextColor,
    fontSize: 18,
    lineHeight: 1.55,
    fontWeight: 800,
    borderColor: 'transparent',
    borderStyle: 'solid',
    '&:hover': {
      opacity: 0.9,
    },
  },
  drawerToolbar: {
    display: 'flex',
    justifyContent: 'center',
  },
  drawerToolbarLogo: {
    width: 140,
    height: 80,
  },
  overrideRipple: {
    // '.MuiTouchRipple-child': {
    //   backgroundColor: 'red',
    // }
  },
  drawerWrapper: {
    height: '100vh',
  },
  drawerFooterWrapper: {
    paddingTop: 100,
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    width: '100%',
    backgroundImage: `url(${bgSvg})`,
    backgroundPositionX: 'right',
    color: theme.palette.white,
    lineHeight: 1.05,
    fontWeight: 700,
    fontSize: 16,
  },
  socialIconsDrawerWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  phoneNumbersWrapper: {
    display: 'flex',
  },
  phoneNumbersIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    margin: 'auto 0',
  },
  drawerNavList: {
    height: 'calc(100vh - 90px - 372px - 1px)',
    maxHeight: 'calc(100vh - 90px - 372px - 1px)',
    overflowY: 'auto',
  },
}));
