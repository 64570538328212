import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  overrideRipple: {},
  listItemLink: {
    width: '100%',
  },
  listItemTextRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  listItemTextPrimary: {
    fontFamily: theme.typography.textsFontFamily,
    color: theme.palette.purpleTextColor,
    fontSize: '14pt',
    lineHeight: 1.55,
    fontWeight: 600,
    textShadow: `0 0 1px ${theme.palette.purpleTextColor}`,
    borderColor: 'transparent',
    borderStyle: 'solid',
    '&:hover': {
      opacity: 0.9,
    },
  },
}));
