const socialLinks = {
  fb: 'https://www.facebook.com/unicat.se',
  facebook: 'https://www.facebook.com/unicat.se/',
  instagram: 'https://www.instagram.com/unicat.se/',
  linkedin: '',
  messenger: 'https://m.me/unicat.se',
  twitter: 'https://twitter.com/unicat_se',
  youtube: '',
  pinterest: '',
  email: 'sales@unicat.se',
};

module.exports.config = {
  DefaultLanguage: 'en',
  phones: ['+46 736562609', '+46 761891095'],
  email: ['sales@unicat.se'],
  InstagramPostToShow: 9, // <~ does not influence on anything
  location: {
    title: 'Nykarlebygatan 92,\n16478 Kista / Stockholm',
    geo: '59.4102975,17.9163966',
    link: 'https://www.google.com/maps/search/?api=1&query=59.4102975,17.9163966',
    /** https://stackoverflow.com/questions/23232273/is-there-a-way-to-invoke-navigation-from-mobile-browser/46713932 */
    mobileDeeplink: 'geo:{geo}',
    mobileNavigate: 'google.navigation:q={geo}',
    /** https://developers.google.com/maps/documentation/urls/get-started */
    browserMap: 'https://www.google.com/maps/search/?api=1&query=59.4102975,17.9163966',
    browserNavigate: 'https://maps.google.com/maps/dir/?api=1&destination={geo}&dir_action=navigate&travelmode=driving',
  },
  forms: {
    en: 'https://forms.gle/CFrsQ8Uz4ZYr2cwk6?lang=en',
    ru: 'https://forms.gle/CFrsQ8Uz4ZYr2cwk6?lang=ru',
    ua: 'https://forms.gle/CFrsQ8Uz4ZYr2cwk6?lang=ua',
    sv: 'https://forms.gle/CFrsQ8Uz4ZYr2cwk6?lang=sv',
  },
  socialLinks,
  siteMetadata: {
    title: 'Unicat SE',
    description: 'Unicorn Cat (UniCat) is modern Swedish confectionery. Place of your unique cakes.',
    author: 'ArtfulBits IT AB <sales@artfulbits.se>',
    siteUrl: 'https://unicat.se',
    siteImage: '/unicat-preview_1200x627.jpg',
    social: [
      {
        name: 'facebook',
        url: 'https://facebook.com',
      },
    ],
    twitter: {
      image: '/unicat-preview_560x300.jpg',
      author: '@unicat_se',
    },
    socialLinks,
  },
};

/**
 * Refs:
 * - Recommended Image sizes for Twitter and Facebook:
 * https://neilpatel.com/blog/open-graph-meta-tags/#:~:text=The%20most%20frequently%20recommended%20resolution,stand%20out%20from%20the%20crowd.
 * */
