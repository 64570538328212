import React, { useContext } from 'react';
import t from '@unicat/i18n/Translate';
import { CookieBanner } from '@palmabit/react-cookie-law';
import Language from '@unicat/i18n/LanguageContext';
import { styles, useStyles } from './styles';

export default function TransitionsSnackbar() {
  useStyles(); // <~ run to compose styles object
  const language = useContext(Language);
  const selectedLang = language.lang;
  const policyLink = `${selectedLang ? '/' + selectedLang : ''}/privacy`;

  return (
    <>
      <CookieBanner
        message={t('i18n:cookies:message')}
        policyLink={policyLink}
        className={'cookies-dialog'}
        styles={styles}
        wholeDomain={true}
        onAccept={() => {}}
        onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {}}
        onAcceptMarketing={() => {}}
        showPreferencesOption={true}
        showStatisticsOption={true}
        showMarketingOption={true}
      />
    </>
  );
}
