import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from '@unicat/TopMenu/Drawer/style';
import SocialButtons from '@unicat/SocialButtons';
import { ReactComponent as PhoneWhite } from '@assets/icons/phone_32_px_white.svg';
import { ReactComponent as MailWhite } from '@assets/icons/mail_32_px_white.svg';
import t from '@unicat/i18n/Translate';

const SmallFooter = ({ config }) => {
  const classes = useStyles();
  const { phones, email } = config;

  return (
    <>
      <div className={classes.drawerFooterWrapper}>
        <SocialButtons config={config} />
        <br />
        <br />
        {/** phones */}
        <div className={classes.drawerFooterSection}>
          <div className={classes.phoneNumbersIcon}>
            <PhoneWhite />
          </div>
          <div className={clsx([classes.phoneNumbersInfo])}>
            {(phones || []).map((item) => (
              <a href={`tel:${item}`} key={item} className={clsx([classes.big, classes.phoneNumber])}>
                {item}
              </a>
            ))}
          </div>
        </div>
        {/** email */}
        <div className={classes.drawerFooterSection}>
          <div className={classes.emailSectionIcon}>
            <MailWhite />
          </div>
          <div className={clsx([classes.emailSectionInfo])}>
            {(email || []).map((item) => (
              <a
                href={`mailto:${item}`}
                key={item}
                className={clsx([classes.big, classes.emailAddress])}
                title={t('Email Us')}>
                {item}
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

SmallFooter.defaultProps = {};

SmallFooter.propTypes = {
  config: PropTypes.shape({
    DefaultLanguage: PropTypes.string,
    phones: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.shape({
      title: PropTypes.string,
      geo: PropTypes.string,
      link: PropTypes.string,
      mobileDeeplink: PropTypes.string,
      mobileNavigate: PropTypes.string,
      browserMap: PropTypes.string,
      browserNavigate: PropTypes.string,
    }),
    socialLinks: PropTypes.shape({
      fb: PropTypes.string,
      instagram: PropTypes.string,
      linkedin: PropTypes.string,
      messenger: PropTypes.string,
      twitter: PropTypes.string,
      youtube: PropTypes.string,
    }),
    forms: PropTypes.shape({
      en: PropTypes.string,
      ru: PropTypes.string,
      ua: PropTypes.string,
      sv: PropTypes.string,
    }),
  }),
};

export default SmallFooter;
