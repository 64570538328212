import { makeStyles } from '@material-ui/core/styles';
import bgSvg from '@backgrounds/TopMenu/bg_footer.svg';

const drawerWidth = 360;

// 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerWrapper: {
    height: '100vh',
  },
  /** toolbar */
  drawerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: theme.dimensions.toolbarHeight,
  },
  drawerToolbarLogo: {
    width: 90,
    height: 30,
  },
  drawerToolbarClose: {
    marginRight: '16px',
    maxWidth: '27px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapperLangSwAndClose: {
    display: 'flex',
    alignItems: 'center',
  },
  /** menu items */
  drawerNavList: {
    height: 'calc(100% - 10px - 372px - 1px)',
    maxHeight: 'calc(100% -10px - 372px - 1px)',
    overflowY: 'auto',
  },
  /** small footer */
  drawerFooterWrapper: {
    paddingTop: 100,
    paddingBottom: theme.spacing(6),
    paddingLeft: '1rem',
    width: '100%',
    backgroundImage: `url(${bgSvg})`,
    backgroundPositionX: 'right',
    color: '#fff',
    fontFamily: theme.typography.textsFontFamily,
    fontWeight: 600,
    fontSize: '12pt',
    textShadow: '0 0 1px #fff', // do extra text boldness

    lineHeight: 1.05,
  },
  /** phones */
  drawerFooterSection: {
    display: 'flex',
    paddingLeft: '4rem',
  },
  phoneNumbersIcon: {
    minWidth: '52px',
  },
  phoneNumbersInfo: {},
  phoneNumber: {
    display: 'block',
    fontSize: '0.95rem',
    fontWeight: 600,
    // do extra text boldness
    textShadow: '0 0 1px #fff',
    marginBottom: '0.5rem',
  },
  /** email */
  emailSectionIcon: {
    minWidth: '52px',
  },
  emailSectionInfo: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  emailAddress: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
}));
