import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import clsx from 'clsx';
import MUIAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import { routesList } from '@config/headerRoutes';
import { socialIconsHeader } from '@config/socialIcons';
import t from '@unicat/i18n/Translate';
import LocalizedLink from '@unicat/i18n/LocalizedLink';
import LanguageSwitcher from '@unicat/i18n/LanguageSwitcher';
import { useStyles } from './style';
import { ReactComponent as Logo } from '@assets/logo_unicat_se_purple.svg';
import { ReactComponent as BurgerIcon } from '@assets/icons/burger.svg';

const RenderSvg = ({ Icon }) => {
  return <Icon />;
};

RenderSvg.propTypes = {
  Icon: PropTypes.any.isRequired,
};

const AppBar = ({ handleDrawerToggle }) => {
  const classes = useStyles();

  const [stuck, setStuck] = useState(false);
  const headerRef = useRef(null);

  const handleScroll = () => {
    if (headerRef.current) {
      setStuck(headerRef.current.getBoundingClientRect().top > document.body.getBoundingClientRect().top);
    }
  };
  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <MUIAppBar ref={headerRef} position="fixed" className={clsx([classes.appBar, stuck ? classes.stuck : ''])}>
      <Toolbar classes={{ root: classes.toolbar }}>
        <LocalizedLink className={classes.leftBar} to="/">
          <Logo className={classes.logo} alt={t('Unicat - Swedish Unique Cake Confectionery')} />
        </LocalizedLink>

        <div className={classes.nav}>
          <Hidden xsDown>
            {map(routesList, (x) => (
              <LocalizedLink className={classes.link} key={x.to} to={x.to}>
                {t(x.name)}
              </LocalizedLink>
            ))}
          </Hidden>
        </div>
        <Hidden xsDown>
          <div className={clsx(classes.socialIconsWrapper, classes.rightBar)}>
            <LanguageSwitcher />
            {map(socialIconsHeader, (x) => (
              <a
                className={classes.socialIcon}
                key={x.to}
                href={x.to}
                target={'_blank'}
                rel={'noreferrer noopener'}
                title={t(x.name)}>
                <RenderSvg Icon={x.src} alt={t(x.name)} />
              </a>
            ))}
          </div>
        </Hidden>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
          className={classes.menuButton}
          role="button"
          tabIndex={0}>
          <BurgerIcon width={36} height={24} />
        </div>
      </Toolbar>
    </MUIAppBar>
  );
};

AppBar.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default AppBar;
