import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import routes from '@config/routes';
import { config } from '@config';

const { DefaultLanguage } = config;
const ROUTES_MAPPED = new Map();
const KEY_LANG = 'lang';

const initialize = () => {
  for (const pairArray of routes) {
    if (pairArray?.length !== 2) throw new Error('broken routes configuration');

    const route = pairArray[1];
    const pathList = route.pathList;
    ROUTES_MAPPED.set(pairArray[0], route);

    for (const lang in pathList) {
      ROUTES_MAPPED.set(pathList[lang].path, route);
    }
  }
};

const Language = React.createContext({
  lang: DefaultLanguage,
  toggleLang: () => {},
});

/**
 * Try to extract language from URL path and if not found return fallback value.
 * Examples:
 * <pre>
 * /en/demo ~> en
 * /ru/ ~> ru
 * /something_else?lang=tw ~> en (stored OR fallback!)
 * </pre>
 * */
const _languageFromUrlPath = ({ url, stored = localStorage.getItem(KEY_LANG), fallback = DefaultLanguage }) => {
  let extractedLanguage = stored?.toLowerCase();

  const regExp = /\/(ua|ru|sv|en)\//g;
  const found = regExp.exec(url);
  if (found?.length > 0) {
    extractedLanguage = found[1];
  }

  return extractedLanguage ?? fallback;
};

/** Resolve current URI to route configuration, otherwise 'undefined'. */
const _resolveUriToRoute = (path = window.location.pathname) => {
  const clearedUri = path.replace(/\/$/, '');
  const decodedUri = decodeURI(clearedUri);

  // find current page and corresponding to it route
  // resolves: '/en/demo' ~> '/demo'
  return ROUTES_MAPPED.get(decodedUri);
};

/** Change language and navigate to localized page if available. */
const _toggleLanguageInternal = (setLang) => (lang) => {
  if (typeof window === 'undefined') return;

  // save new language setting
  setLang(lang);
  localStorage.setItem(KEY_LANG, lang);

  const route = _resolveUriToRoute();

  // try to find corresponding page
  const pageId = route?.id === routes.get('').id ? '' : route?.id;
  const newPagePath = routes.get(pageId)?.pathList[lang]?.path;

  // navigate to new URL (new page)
  if (newPagePath) {
    window.history.pushState(null, null, newPagePath);
    return navigate(newPagePath, { replace: newPagePath });
  }
};

const LanguageContext = ({ children }) => {
  const defaultLang = _languageFromUrlPath({ url: window.location.pathname });
  const [lang, setLang] = useState(defaultLang);

  // fix the localStorage 'lang' on after-render cycle if not set yet
  useEffect(() => {
    const urlLang = _languageFromUrlPath({ url: window.location.pathname });
    localStorage.setItem(KEY_LANG, urlLang);
  }, []);

  const toggleLang = _toggleLanguageInternal(setLang);

  return <Language.Provider value={{ lang, toggleLang }}>{children}</Language.Provider>;
};

LanguageContext.propTypes = {
  children: PropTypes.node,
};

// build fast route resolver/lookup map
initialize();

export default Language;
export { LanguageContext, _toggleLanguageInternal, _resolveUriToRoute, _languageFromUrlPath, ROUTES_MAPPED };
