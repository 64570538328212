module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Unicat SE","short_name":"UnicatSE","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"browser","icon":"src/assets/logo_unicat_se_purple_optimized_sq.svg","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e1da89ee4fc75afe7ffa55c81d1886fd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"database":true},"credentials":{"apiKey":"AIzaSyCrGN3cc3tgGS18eVbuMIqbgqaB100CVM0","authDomain":"unicat-se-v1-0-0-alpha.firebaseapp.com","databaseURL":"https://unicat-se-v1-0-0-alpha.firebaseio.com","projectId":"unicat-se-v1-0-0-alpha","storageBucket":"unicat-se-v1-0-0-alpha.appspot.com","messagingSenderId":"627539813979","appId":"1:627539813979:web:4a70f6a4a262d1d4a1417e","measurementId":"G-1NY94FDE5C"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2317136925063494"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5P48WJW"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
