import { makeStyles } from '@material-ui/core/styles';

// 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const useStyles = makeStyles((theme) => ({
  /** container */
  socialLinkWrapper: {
    display: 'flex',
    gridGap: '1rem',
    justifyContent: 'left',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      gridGap: '0.5rem',
    },
  },
  /** Buttons */
  link: {
    width: 60,
    height: 60,
    margin: '0 0.5rem',
    '& svg': {
      fontSize: '2rem',
    },
    transition: 'transform 0.25s',
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        transform: 'scale(0.9)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'unset',
      transform: 'scale(0.8)',
      transformOrigin: 'center',
    },
  },
  fb: {
    display: 'flex',
    '& svg': {
      margin: 'auto',
      color: 'white',
    },
  },
  fbm: {
    display: 'flex',
    '& svg': {
      margin: 'auto',
      color: 'white',
    },
  },
  insta: {
    display: 'flex',
    '& svg': {
      margin: 'auto',
      color: 'white',
    },
  },
  twitter: {
    display: 'flex',
    '& svg': {
      margin: 'auto',
      color: 'white',
    },
  },
}));
