import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    // minHeight: 90,
  },
  content: {
    flexGrow: 1,
  },
  debug: {
    ...theme.typography.normalGrayText,
  },
}));
