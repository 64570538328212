import React from 'react';
import AppBar from '@unicat/TopMenu/AppBar';
import Drawer from '@unicat/TopMenu/Drawer';

const TopMenu = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <>
      <AppBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <Drawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
    </>
  );
};

export default TopMenu;
