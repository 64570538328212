import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import replace from 'lodash/replace';
import { Link } from 'gatsby';
import routes from '@config/routes';
import Language, { ROUTES_MAPPED } from '../LanguageContext';
import { config } from '@config';

const url = require('url');

function LocalizedLink({ to = '/', fallbackLanguage = config.DefaultLanguage, ...props }) {
  const language = useContext(Language);
  const { lang } = language ?? { lang: fallbackLanguage };
  const query = url.parse(to);
  let pageUri = null;
  let resolvedUrl = to;

  // pass all external links without modifications
  if (query.host == null) {
    // resolve page path
    if (query.pathname) {
      // replace '/' first char in a path if it set
      const pth = replace(query.pathname, /^\//, '');

      // find current page and corresponding to it route
      const route = ROUTES_MAPPED.get(decodeURI(pth));

      // try to find localized version, if not exists fallback to original query.pathname
      const pageId = route?.id === routes.get('').id ? '' : route?.id;
      pageUri = routes.get(pageId)?.pathList?.[lang]?.path ?? query.pathname;
    }

    resolvedUrl = `${pageUri ?? ''}${query.search ?? ''}${query.hash ?? ''}`;
  }

  return <Link {...props} to={resolvedUrl} />;
}

LocalizedLink.defaultProps = {
  to: '/',
  fallbackLanguage: config.DefaultLanguage,
};

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  fallbackLanguage: PropTypes.string,
};

export default LocalizedLink;
