import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  switcherWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginRight: '1.2rem',
      marginLeft: 'auto',
    },
  },
  langSelector: {
    padding: 0,
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      borderBottom: `4px solid ${theme.palette.darkPurple}`,
    },
    '& div.MuiSelect-select.MuiSelect-select': {
      padding: 0,
      height: 16,
    },
    '&:before': {
      border: 0,
    },
  },
  langItem: {},
  langIcon: {
    marginRight: '0.2rem',
  },
  flags: {
    maxWidth: '21px',
  },
}));
