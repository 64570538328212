import { makeStyles } from '@material-ui/core/styles';

const styles = {};

export const useStyles = makeStyles((theme) => {
  Object.assign(styles, {
    dialog: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100000,
      backgroundColor: 'transparent',
      padding: '1rem',
    },
    container: {
      backgroundColor: theme.palette.pinkTextColor,
      padding: '1rem',
      borderRadius: '1rem',
    },
    message: {
      color: theme.palette.darkPurpleTextColor,
      fontSize: '0.75rem',
    },
    selectPane: {
      paddingTop: '1rem',
      display: 'inline-block',
      verticalAlign: 'bottom',
    },
    option: {},
    optionLabel: {
      textIndent: '1.5rem',
      paddingTop: '3px',
      display: 'block',
    },
    buttonWrapper: {
      marginTop: '-1.5rem',
      display: 'inline',
      float: 'right',
    },
    button: {
      ...theme.actionLinkButton,
      ...theme.typography.actionPurpleText,
      ...theme.buttonOutline,
    },
  });

  return styles;
});

export { styles };
