'use strict';
import { default as AboutUs } from './AboutUs.json';
import { default as CTA } from './CTA.json';
import { default as CookiesNotification } from './CookiesNotification.json';
import { default as Footer } from './Footer.json';
import { default as GetDiscount } from './GetDiscount.json';
import { default as HomeBanner } from './HomeBanner.json';
import { default as InstaFeed } from './InstaFeed.json';
import { default as Jest } from './Jest.json';
import { default as Ordering } from './Ordering.json';
import { default as OurAdvantages } from './OurAdvantages.json';
import { default as Portfolio } from './Portfolio.json';
import { default as ShortInto } from './ShortInto.json';
import { default as Testimonials } from './Testimonials.json';
import { default as TopMenu } from './TopMenu.json';
import { default as WeCreate } from './WeCreate.json';

const ZZ = [
  AboutUs,
  CTA,
  CookiesNotification,
  Footer,
  GetDiscount,
  HomeBanner,
  InstaFeed,
  Jest,
  Ordering,
  OurAdvantages,
  Portfolio,
  ShortInto,
  Testimonials,
  TopMenu,
  WeCreate,
];
export default ZZ;
