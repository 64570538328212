import { config } from '@config';
import { ReactComponent as Instagram } from '@assets/icons/socials/insta_logo.svg';
import { ReactComponent as Facebook } from '@assets/icons/socials/facebook_logo.svg';
import { ReactComponent as Messenger } from '@assets/icons/socials/messenger_logo.svg';
import { ReactComponent as Twitter } from '@assets/icons/socials/twitter_logo.svg';

export const socialIconsHeader = [
  {
    name: 'Instagram',
    to: config.socialLinks.instagram,
    src: Instagram,
  },
  {
    name: 'Messenger',
    to: config.socialLinks.messenger,
    src: Messenger,
  },
  {
    name: 'Facebook',
    to: config.socialLinks.fb,
    src: Facebook,
  },
  {
    name: 'Twitter',
    to: config.socialLinks.twitter,
    src: Twitter,
  },
];
