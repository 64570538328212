/* eslint-disable-next-line no-unused-vars */
import React, { useContext } from 'react';
import forEach from 'lodash/forEach';
import * as locales from '../../../locales';
import { config } from '@config';
import Language from '../LanguageContext';

const DEFAULT = config.DefaultLanguage.toUpperCase();
const I18 = JSON.parse(JSON.stringify(locales));

// WARNING! same keys will be overridden
const initialize = () => {
  for (const key in I18) {
    const res = {};

    // merge sub-json files into one.
    // noinspection JSUnfilteredForInLoop
    forEach(I18[key], (x) => {
      Object.assign(res, x);
    });

    // replace with merged value
    // noinspection JSUnfilteredForInLoop
    I18[key] = res;
  }
};

export const localize = (string, lang) => {
  const language = I18[lang.toUpperCase()];
  const hasFallback = I18[DEFAULT][string] !== undefined;
  const locale = language === undefined ? DEFAULT : lang.toUpperCase();

  return I18[locale][string] ?? (hasFallback ? I18[DEFAULT][string] : string);
};

const t = (string) => {
  const language = useContext(Language);
  const { lang } = language ?? { lang: DEFAULT };
  return localize(string, lang ?? DEFAULT);
};

// compose complete localization I18 dictionary
initialize();

export default t;
