import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useStyles } from './style';
import LocalizedLink from '@unicat/i18n/LocalizedLink';

const Route = ({ to, name }) => {
  const classes = useStyles();

  return (
    <ListItem button classes={{ root: classes.overrideRipple }}>
      <LocalizedLink to={to} className={clsx(classes.listItemLink)}>
        <ListItemText
          classes={{
            root: classes.listItemTextRoot,
            primary: classes.listItemTextPrimary,
          }}
          primary={name}
        />
      </LocalizedLink>
    </ListItem>
  );
};

Route.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Route;
