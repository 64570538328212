import React, { useContext } from 'react';
import clsx from 'clsx';
import t from '@unicat/i18n/Translate';
import language_config from '@config/language-config';
import Language from '../LanguageContext';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import Flag from 'react-world-flags';
import { ReactComponent as Flag_UA } from 'svg-country-flags/svg/ua.svg';
import { ReactComponent as Flag_RU } from 'svg-country-flags/svg/ru.svg';
import { ReactComponent as Flag_GB } from 'svg-country-flags/svg/gb.svg';
import { ReactComponent as Flag_SE } from 'svg-country-flags/svg/se.svg';

const languages = Object.keys(language_config);

const LanguageSwitcher = () => {
  const language = useContext(Language);
  const { lang, toggleLang } = language;
  const theme = useTheme();

  const handleSelect = (e) => {
    const selected_language = e.target.value;
    toggleLang(selected_language);
  };

  const classes = useStyles();

  const renderFlag = (lang) => {
    let Flag = Flag_GB;
    if (lang === 'ua') {
      Flag = Flag_UA;
    } else if (lang === 'ru') {
      Flag = Flag_RU;
    } else if (lang === 'sv') {
      Flag = Flag_SE;
    }

    return <Flag height={14} className={clsx(classes.flags)} alt={language_config[lang].displayName} />;
  };

  return (
    <div className={classes.switcherWrapper}>
      <Select
        style={{ color: theme.palette.primary.dark }}
        IconComponent={() => <></>}
        value={lang}
        onChange={handleSelect}
        className={classes.langSelector}
        aria-label={t('WebSite Language Switcher')}>
        {languages.map((lang) => (
          <MenuItem key={lang} value={lang} className={clsx(classes.langItem)}>
            {/* https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3 */}
            {renderFlag(lang)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default LanguageSwitcher;
