import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useStyles } from './styles';
import { LanguageContext } from '@unicat/i18n/LanguageContext';
import { ThemeProvider } from '@material-ui/core';
import theme from '@styles/theme';
import TopMenu from '@unicat/TopMenu';
import NotificationBar from '@unicat/UI/CookiesNotification';

const query = graphql`
  query LanguageContextQuery {
    sitePage {
      context {
        id
        language
        pageName
      }
    }
  }
`;

function Layout({ children }) {
  const classes = useStyles();

  // demo of component graphql query
  const data = useStaticQuery(query);
  // eslint-disable-next-line no-console
  console.debug(data);

  return (
    <ThemeProvider theme={theme}>
      <LanguageContext>
        <CssBaseline />
        <TopMenu />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
        <NotificationBar />
      </LanguageContext>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.element,
};

export default Layout;
