import React from 'react';
import PropTypes from 'prop-types';

const SocialLink = ({ children, ...props }) => {
  // eslint-disable-next-line react/prop-types
  const { href } = props;

  return (
    <>
      {href && (
        <a rel={'noreferrer noopener'} target={'_blank'} {...props}>
          {children}
        </a>
      )}
    </>
  );
};

SocialLink.propTypes = {
  children: PropTypes.object.isRequired,
  props: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      href: PropTypes.string.isRequired,
    }),
  ]),
};

export default SocialLink;
